<template>
  <PageModal content-class="max-w-[994px]" @close="$emit('close')">
    <form @submit.prevent="apply">
      <InputDropdownCombobox
        v-model="selectedCountry"
        :items="countryOptions"
        class="mb-40 w-full md:w-[306px]"
        icon="globe"
      />
      <div class="md:columns-3">
        <InputRadioGroup
          v-model="selectedLanguage"
          :options="languageOptions"
          name="language-switcher"
        />
      </div>
      <button
        class="mt-56 block w-fit rounded-full border border-grey-dark-01 bg-grey-dark-01 px-32 py-10 text-base text-white hover:border-black hover:bg-black mobile-only:sticky mobile-only:bottom-0 mobile-only:w-full md:mt-[100px]"
        @click.prevent="apply"
      >
        {{ $texts('applyLanguageSwitcher', 'Apply') }}
      </button>
    </form>
  </PageModal>
</template>

<script lang="ts" setup>
import { falsy } from '~/helpers/type'

const props = defineProps<{
  currentLanguage: string
  currentCountry: string
}>()

const emit = defineEmits(['close'])

const data = await useLocaleData()
const languageLinks = await usePageLanguageLinks()

// Initialize selection with current language and country values.
// Because we show this component in a modal, there is no way for these to
// change, so we can skip reactivity in this case.
const selectedLanguage = ref<string | null>(props.currentLanguage)
const selectedCountry = ref<string | null>(props.currentCountry)

const countryOptions = computed(() =>
  data.value.countries.map((v) => {
    const currencies = (v.currencies || [])
      .map((v) => v.id)
      .filter(Boolean)
      .join(', ')
    return {
      value: v.code,
      label: currencies ? `${v.label}, ${currencies}` : v.label,
    }
  }),
)

const languageOptions = computed(() =>
  data.value.languages
    .map((v) => {
      if (!v.id) {
        return null
      }
      return {
        value: v.id,
        label: v.name,
        disabled: !languageLinks.value.find(
          (link) => link.code === v.id && link.path,
        ),
      }
    })
    .filter(falsy),
)

const apply = () => {
  // Check if there was actually a change in the selection.
  if (
    props.currentCountry !== selectedCountry.value ||
    props.currentLanguage !== selectedLanguage.value
  ) {
    // Get the target path for the language.
    const targetPath = languageLinks.value.find(
      (v) => v.code === selectedLanguage.value,
    )?.path

    if (targetPath) {
      // Get the new path by replacing the path prefix with the new prefix.
      const newPath = targetPath.replace(
        /^\/([^/]*)/g,
        `/${selectedLanguage.value}-${selectedCountry.value}`,
      )

      window.location.href = newPath
      return
    }
  }

  // Always close the modal in the end.
  return emit('close')
}
</script>
